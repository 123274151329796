<template>
  <div>
    <section id="login_page" class="hero is-fullheight has-text-centered">
      <div class="overlay" />
      <div style="position: relative; z-index: 5;">
        <div class="auth-wrapper">
          <div class="padding-y-6">
            <div class="logo padding-y-6">
              <img id="login_logo" style="width: 70px" src="@/assets/images/JFE-logo-stacked.png">
              <div class="has-text-grey" style="font-size: 18px;">
                S U I T E <br>
              </div>
            </div>
          </div>
          <div v-if="!requestProcessOkay" class="padding-xy-3 box has-shadow-30" style="max-width: 360px; margin: 0 auto;">
            Reset password link is invalid or expired. Please try to <a href="/login">reset your password</a> again.
            <!-- <span v-if="hasExpired"><p>Link has expired. Please try to reset your password again.</p></span> -->
          </div>
          <div v-if="requestProcessOkay" class="row auth-form padding-y-3">
            <form class="form" @submit.prevent="submit">
              <h1 class="is-size-5 has-text-weight-bold" style="padding-bottom: 20px">
                Reset Password
              </h1>
              <div id="login_form">
                <div v-if="error" class="padding-y-3">
                  <b-tag type="is-warning">
                    {{ error }}
                  </b-tag>
                </div>
                <b-field v-show="!loginResponse" position="is-centered" :type="{'is-danger': errors.has('username')}" :message="errors.first('username')">
                  <b-input
                    id="username"
                    v-model="username"
                    v-validate="'required'"
                    expanded
                    placeholder="Email"
                    type="text"
                    :disabled="true"
                    icon="user"
                    name="username" />
                </b-field>
                <b-field
                  v-show="!loginResponse"
                  position="is-centered"
                  :type="{'is-danger': errors.has('password')}"
                  :message="[{
                    'New password field is required' : errors.firstByRule('password', 'required'),
                    'Password must be at least 8 characters' : errors.firstByRule('password', 'min')
                  }]">
                  <b-input
                    v-model="password"
                    v-validate="'required|min:8'"
                    expanded
                    placeholder="New Password"
                    type="password"
                    icon="lock"
                    name="password" />
                </b-field>
                <b-field
                  v-show="!loginResponse"
                  position="is-centered"
                  :type="{'is-danger': errors.has('confirmPassword')}"
                  :message="[{
                    'Please confirm your new password' : errors.firstByRule('confirmPassword', 'required'),
                    'Passwords fields do not match' : errors.firstByRule('confirmPassword', 'is')
                  }]">
                  <b-input
                    v-model="confirmPassword"
                    v-validate="{ required: true, is: password }"
                    expanded
                    placeholder="Confirm password"
                    type="password"
                    icon="lock"
                    name="confirmPassword" />
                </b-field>
                <b-field v-show="!!userRequest.u2fEnabled" position="is-centered" :type="{'is-danger': errors.has('secretKey')}" :message="errors.first('secretKey')">
                  <b-input
                    id="secretKey"
                    v-model="secretKey"
                    v-validate="{ required: !!userRequest.u2fEnabled }"
                    expanded
                    placeholder="Secret Key"
                    type="password"
                    icon="star"
                    name="secretKey" />
                </b-field>
                <div v-if="u2fMessage" class="padding-y-3">
                  <b-tag type="is-warning">
                    {{ u2fMessage }}
                  </b-tag>
                </div>
                <b-message type="is-danger" title="Error" :active.sync="isYubikeyMessageError" aria-close-label="Close message">
                  {{ yubikeyMessageError }}
                </b-message>
              </div>
              <div>
                <input
                  id="login_btn"
                  class="button is-expanded is-primary"
                  type="submit"
                  value="Save New Password">
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import AccountApi from '@/apis/AccountApi'
import UsersApi from '@/apis/UsersApi'
import EmailsApi from '@/apis/EmailsApi'
import Moment from 'moment'
const { Yubico } = require('yubico-node')

export default {
  name: 'Login',
  data () {
    return {
      userRequest: null,
      requestProcessOkay: false,
      hasExpired: false,
      password: null,
      confirmPassword: null,
      secretKey: null,
      error: null,
      u2fMessage: null,
      busy: false,
      yubikeyMessage: null,
      yubikeyMessageError: null,
      showForgotPassword: false,
      gLength: 9,
      characters: [
        {
          name: 'Lowercase',
          value: 'abcdefghijklmnopqrstuvwxyz',
          checked: true
        },
        {
          name: 'Uppercase',
          value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
          checked: true
        },
        {
          name: 'Numbers',
          value: '0123456789',
          checked: true
        },
        {
          name: 'Special Characters',
          value: '_-+=)(*&%$#@!`~',
          checked: false
        }
      ],
      notificationEmail: {
        emailTo: {
          email: null,
          name: null
        },
        subject: null,
        textPart: null,
        body: null
      }
    }
  },
  computed: {
    profile () {
      return this.$store.state.profile
    },
    username () {
      return this.$route.params.username
    },
    accountHash () {
      return this.$route.params.accountHash
    },
    loginResponse: {
      get () {
        return this.$store.state.loginResponse
      },
      set (val) {
        this.$store.commit('setLoginResponse', val)
      }
    },
    isYubikeyMessageError: {
      get () {
        if (this.yubikeyMessageError) {
          return true
        } else {
          return false
        }
      },
      set (val) {
        return val
      }
    }
  },
  watch: {
    async 'profile' () {
      this.$router.push({ name: 'login' })
    },
    async 'loginResponse' (response) {
      if (response) {
        this.$nextTick(() => {
          // The whole view is rendered, so I can safely access or query
          // the DOM. ¯\_(ツ)_/¯
          document.getElementById('secretKey').focus()
        })
        if (this.secretKey) {
          const loadingComponent = this.$buefy.loading.open({ container: null })
          this.yubikeyMessageError = null

          const yubic = new Yubico({
            clientId: process.env.VUE_APP_YUBICO_CLIENT_ID,
            secret: process.env.VUE_APP_YUBICO_SECRET,
            sl: 100,
            timeout: 30
          })

          try {
            var res = await yubic.verify(this.secretKey)

            if (res.getStatus() === 'OK') {
              if (response.profile.yubikeyIdentity === res.getPublicId()) { // Right User
                await this.$store.dispatch('refreshLoginU2F', this.loginResponse)
                this.loginResponse = null
                this.u2fMessage = null
              } else { // Wrong User
                if (response.profile.yubikeyIdentity) { // User already ahs a Yubikey? Maybe somebody is trying hack it
                  this.yubikeyMessageError = 'Invalid dongle.'
                  this.secretKey = null
                  document.getElementById('secretKey').focus()
                } else {
                  try { // Save the Yubikey for this user
                    // Check Serial Yubikey used
                    var yubikeyUser = await UsersApi.getUserByYubikeySerial(res.getSerialNumber()) // If an existing user has used the same dongle, function throw a error
                    // var maskid = yubikeyUser.username.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c)
                    this.yubikeyMessageError = 'This dongle is already assigned to ' + yubikeyUser.firstName + ' ' + yubikeyUser.lastName.charAt(0)
                    this.secretKey = null
                    document.getElementById('secretKey').focus()
                  } catch (err) {
                    try {
                      // The Serial is free to use
                      response.profile.yubikeyIdentity = res.getPublicId()
                      response.profile.yubikeySerial = res.getSerialNumber()
                      response.profile.u2fKeyRegistration = JSON.stringify(res)

                      // Lets save the Yubikey
                      await UsersApi.updateUserYubikey(response.profile.id, response.profile)
                      // Go ahead with Login
                      await this.$store.dispatch('refreshLoginU2F', this.loginResponse)
                      this.loginResponse = null
                      this.u2fMessage = null
                    } catch (err) {
                      this.yubikeyMessageError = err
                    }
                  }
                }
              }
            } else {
              // Maybe this will never happens because this plugin thrwo an error status different "OK"
              this.yubikeyMessageError = 'Dongle validation expired. Please try again.'
              this.secretKey = null
              document.getElementById('secretKey').focus()
            }
          } catch (e) {
            this.yubikeyMessageError = e
          } finally {
            loadingComponent.close()
          }
        } else {
          this.u2fMessage = 'Insert and tap your dongle'
          document.getElementById('secretKey').focus()
        }
      }
    }
  },
  mounted () {
    this.startRequestProcess()

    this.$store.commit('setTitle', 'Reset Password')
    this.$store.commit('setApp', null)
  },
  methods: {
    async startRequestProcess () {
      this.userRequest = await UsersApi.getUserByUsername(this.username)

      try {
        if (this.userRequest) {
          if (this.userRequest.passwordHash === this.accountHash) {
            // Check if the hash is expired (1 day)
            var endDateHashSplitted = Moment(this.userRequest.passwordHashDate).format('YYYY-MM-DD').split('-')
            var passwordHashDate = new Date(endDateHashSplitted[0], endDateHashSplitted[1] - 1, endDateHashSplitted[2])
            const expiredHashDate = new Date(passwordHashDate.getFullYear(), passwordHashDate.getMonth(), passwordHashDate.getDate() + 1, 0)
            const d = new Date()
            // 1 day to expired
            if (d > expiredHashDate) {
              this.hasExpired = true
              console.log('Hash just expired')
            } else {
              this.requestProcessOkay = true
            }
          } else {
            console.log('This user is not authorized to access this system')
          }
        } else {
          console.log('This user is not authorized to access this system')
        }
      } catch (e) {
        this.requestProcessOkay = false
        console.error(e)
      }
    },
    royaltyApp () {
      this.$store.commit('setApp', 'royalty')
      this.$router.push({ name: 'royalty_tracker_projects' })
    },
    datasheetApp () {
      this.$store.commit('setApp', 'datasheet')
      this.$router.push({ name: 'datasheet_generator' })
    },
    technicalApp () {
      this.$store.commit('setApp', 'technical')
      this.$router.push({ name: 'technical_documents' })
    },
    async submit () {
      if (await this.$validator.validateAll()) {
        try {
          this.yubikeyMessageError = null
          this.error = null
          this.u2fMessage = null
          //= ================================================
          // Update the password
          //= ================================================
          this.userRequest.password = this.password
          this.userRequest.passwordHash = null
          this.userRequest.passwordHashDate = null
          await UsersApi.updateUserForgotPassword(this.userRequest.id, this.userRequest)
          //= ================================================
          // Send notification to user about password updated
          //= ================================================
          this.sendAlertEmail()
          //= ================================================
          // Update the login vars
          //= ================================================
          await this.$store.dispatch('login', {
            username: this.username,
            password: this.password,
            secretKey: this.secretKey
          })
        } catch (err) {
          console.error(err)
          this.error = err.response.data.message
        }
      }
    },
    async sendAlertEmail () {
      const subject = 'Your JFE Tools password has been changed'
      const body = this.userRequest.firstName + ', the password for your JFE Tools profile has been changed.' +
        '<p>If you did not make this change, please contact your JFE account administrator.</p>'

      await this.sendEmailToUser(subject, body, this.userRequest).then((mailResult) => {
        if (mailResult.Status !== undefined) {
          if (mailResult.Status === 'success') {
            // Do something?
          } else {
            console.log('#201 Error sending email:' + mailResult.ErrorMessage)
          }
        } else {
          console.log('#202 Error sending email:' + JSON.stringify(mailResult.ErrorMessage))
        }
      })
    },
    async sendEmailToUser (subject, body, user) {
      var env = process.env.NODE_ENV

      this.notificationEmail.emailTo.email = user.username
      this.notificationEmail.emailTo.name = user.firstName + ' ' + user.lastName
      this.notificationEmail.subject = subject
      this.notificationEmail.body = body
      this.notificationEmail.textPart = '<p>Thank you for your business,<br>The JFE Team<br><a href="www.jfetc.com">www.jfetc.com</a>'

      if (env === 'development') {
        this.notificationEmail.emailTo.email = process.env.VUE_APP_EMAIL_DEV
      }

      return EmailsApi.sendEmail(this.notificationEmail)
    }
  }
}
</script>
