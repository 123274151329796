var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"hero is-fullheight has-text-centered",attrs:{"id":"login_page"}},[_c('div',{staticClass:"overlay"}),_c('div',{staticStyle:{"position":"relative","z-index":"5"}},[_c('div',{staticClass:"auth-wrapper"},[_vm._m(0),(!_vm.requestProcessOkay)?_c('div',{staticClass:"padding-xy-3 box has-shadow-30",staticStyle:{"max-width":"360px","margin":"0 auto"}},[_vm._v(" Reset password link is invalid or expired. Please try to "),_c('a',{attrs:{"href":"/login"}},[_vm._v("reset your password")]),_vm._v(" again. ")]):_vm._e(),(_vm.requestProcessOkay)?_c('div',{staticClass:"row auth-form padding-y-3"},[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('h1',{staticClass:"is-size-5 has-text-weight-bold",staticStyle:{"padding-bottom":"20px"}},[_vm._v(" Reset Password ")]),_c('div',{attrs:{"id":"login_form"}},[(_vm.error)?_c('div',{staticClass:"padding-y-3"},[_c('b-tag',{attrs:{"type":"is-warning"}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1):_vm._e(),_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loginResponse),expression:"!loginResponse"}],attrs:{"position":"is-centered","type":{'is-danger': _vm.errors.has('username')},"message":_vm.errors.first('username')}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"id":"username","expanded":"","placeholder":"Email","type":"text","disabled":true,"icon":"user","name":"username"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loginResponse),expression:"!loginResponse"}],attrs:{"position":"is-centered","type":{'is-danger': _vm.errors.has('password')},"message":[{
                  'New password field is required' : _vm.errors.firstByRule('password', 'required'),
                  'Password must be at least 8 characters' : _vm.errors.firstByRule('password', 'min')
                }]}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:8'),expression:"'required|min:8'"}],attrs:{"expanded":"","placeholder":"New Password","type":"password","icon":"lock","name":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loginResponse),expression:"!loginResponse"}],attrs:{"position":"is-centered","type":{'is-danger': _vm.errors.has('confirmPassword')},"message":[{
                  'Please confirm your new password' : _vm.errors.firstByRule('confirmPassword', 'required'),
                  'Passwords fields do not match' : _vm.errors.firstByRule('confirmPassword', 'is')
                }]}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, is: _vm.password }),expression:"{ required: true, is: password }"}],attrs:{"expanded":"","placeholder":"Confirm password","type":"password","icon":"lock","name":"confirmPassword"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1),_c('b-field',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.userRequest.u2fEnabled),expression:"!!userRequest.u2fEnabled"}],attrs:{"position":"is-centered","type":{'is-danger': _vm.errors.has('secretKey')},"message":_vm.errors.first('secretKey')}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:({ required: !!_vm.userRequest.u2fEnabled }),expression:"{ required: !!userRequest.u2fEnabled }"}],attrs:{"id":"secretKey","expanded":"","placeholder":"Secret Key","type":"password","icon":"star","name":"secretKey"},model:{value:(_vm.secretKey),callback:function ($$v) {_vm.secretKey=$$v},expression:"secretKey"}})],1),(_vm.u2fMessage)?_c('div',{staticClass:"padding-y-3"},[_c('b-tag',{attrs:{"type":"is-warning"}},[_vm._v(" "+_vm._s(_vm.u2fMessage)+" ")])],1):_vm._e(),_c('b-message',{attrs:{"type":"is-danger","title":"Error","active":_vm.isYubikeyMessageError,"aria-close-label":"Close message"},on:{"update:active":function($event){_vm.isYubikeyMessageError=$event}}},[_vm._v(" "+_vm._s(_vm.yubikeyMessageError)+" ")])],1),_vm._m(1)])]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"padding-y-6"},[_c('div',{staticClass:"logo padding-y-6"},[_c('img',{staticStyle:{"width":"70px"},attrs:{"id":"login_logo","src":require("@/assets/images/JFE-logo-stacked.png")}}),_c('div',{staticClass:"has-text-grey",staticStyle:{"font-size":"18px"}},[_vm._v(" S U I T E "),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('input',{staticClass:"button is-expanded is-primary",attrs:{"id":"login_btn","type":"submit","value":"Save New Password"}})])
}]

export { render, staticRenderFns }